import React, { useState, useEffect } from "react"
import { Form } from "react-bootstrap"

import InputField from './elements/input'
import SelectField from './elements/select'
import TextAreaField from './elements/textarea'
import CheckboxField from './elements/checkbox'
import ButtonField from './elements/button'
import HtmlBox from './elements/html'
import ReCaptchaBox from './elements/recaptcha'
import './assets/styles/_index.scss'
import { postFormData, postStbFormData } from "./api/Api"
import { phoneNumberValidation, uniqueID, Mail_Function, raptorFormSchema } from "../common/utils"
import { PropertyBaseAPI } from "../../queries/api_links"

import axios from "axios"
import * as qs from "query-string"
import { navigate } from "@reach/router"

function PrequalificationForm(props) {

  const [validated, setValidated] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showthankyou, setThankyou] = useState(false);

  const [formvalues, setFormvalues] = useState("");

  const [token, setToken] = useState("");

  const myRef = React.createRef();

  const recaptchaRef = React.createRef();


  const fields = ([
      {
        element: "config",
        formname: "Get Prequalified - "+props.form_name,
        form_type: "pre-qualified-"+props.form_type,
        error_text: "Highlighted fields are required | invalid",
        success_text: "Thank you for contacting us. A member of our team will contact you shortly.",
        email_temp_user: "prequalified_user",
        email_temp_admin: "prequalified_admin",
        email_server_func: "prequalified",
        event_tracking: "prequalified-"+props.form_type,
        page_url: "/pre-qualified/"+props.form_type,
        email_subject_user: "Get Prequalified - "+props.form_name+' Submission',
        email_subject_admin: "Get Prequalified - "+props.form_name+' Submission'
      },
      {
        grpmd: "12",
        label: "First Name",
        placeholder: "",
        name: "first_name",
        type: "text",
        element: "input",
        required: true,
        patternchk: "^[a-zA-Z0-9]+(\\s+[a-zA-Z0-9]+)*$",
        labelClass: "annual-txt"
      },
      {
        grpmd: "12",
        label: "Last Name",
        placeholder: "",
        name: "last_name",
        type: "text",
        element: "input",
        required: true,
        patternchk: "^[a-zA-Z0-9]+(\\s+[a-zA-Z0-9]+)*$",
        labelClass: "annual-txt"
      },
      {
        grpmd: "12",
        label: "Phone Number",
        placeholder: (props?.url && props?.url?.indexOf('dubai')>= 0) ? "+971 55 152 0502":"+974 5086 7771",
        name: "telephone",
        type: "text",
        element: "input",
        required: true,
        // patternchk:"^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
        labelClass: "annual-txt"

      },
      {
        grpmd: "12",
        label: "Email Address",
        placeholder: "",
        name: "email",
        type: "email",
        element: "input",
        required: true,
        patternchk:"[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$",
        labelClass: "annual-txt"

      },
      {
        grpmd: "6",
        element: "anchor",
        value: "Previous Step",
      },
      {
        grpmd: "6",
        name: "SUBMIT",
        type:"submit",
        element: "button",
        value: "Submit",
        class: "btn-primary col-md-12",
        labelClass: "",
        customClass:"btn-wrapper "
      },
      
      // {
      //   text: 'By clicking submit I agree to the Pearlgates <a href="/terms-and-conditions" className="content-link">Terms & Conditions</a>',
      //   element: "html",
      //   class: "mb-md-0 mb-3 mt-md-5 mt-3 content_r-m text-center"
      // },
      {
        element: "captcha",
        class: "py-2",
        captchaRef: recaptchaRef
      },
    ]);

  const handlechange = event => {
    // remove initial empty spaces
    //console.log("select_event",event);
    if(event.type === "select"){
      event.value = event.value.trimStart()
    } else{
      if(event.target.name === "telephone"){
        var valid_phone = phoneNumberValidation(event.target.value);
        if(valid_phone){
          event.target.value = valid_phone;
        } else{
          event.target.value = '';
        }
      } else{
        event.target.value = event.target.value.trimStart()
      }
    }

  }

  useEffect(() => {

    //console.log("token", token);
    
    if (token !== '') {

 const processFromData = async () => {

      let formData = new FormData();

      formData.append('data', JSON.stringify(formvalues));

      postFormData(formData).then(async apiRes => {

        formvalues['g_recaptcha_response'] = token;
        await Mail_Function(formvalues);
        window.grecaptcha.reset();

        if(apiRes){

          /* ======== Property-base API ======== */
          //Sales Leads
          var record_type_id = "";
          if(props.form_type === "commercial-sale"){
            record_type_id = "&contact[RecordTypeId]=0124v0000018HKFAA2";
          } else if(props.form_type === "secondary-market"){
            record_type_id = "&contact[RecordTypeId]=0124v0000018HKKAA2";
          }

          var query_params = "contact[FirstName]="+formvalues.first_name+"&contact[LastName]="+formvalues.last_name+"&contact[Email_2__c]="+formvalues.email+"&contact[MobilePhone]="+formvalues.telephone+"&contact[LeadSource]=website"+record_type_id+"&contact[New_Website__c]=true&contact[Subscribed_For_Monthly_Newsletter__c]=true&contact[pba__LeadRoutingCompleted__c]=false&"+props.form_post_params;

          if(props.property_id){
            query_params += "&contact[pba__Comment_pb__c]="+props.property_id;

            if(props.sublisting_type){
              query_params += "&contact[Lead_Type__c]="+props.sublisting_type;
            }

            // Website views
            if(props?.prevState?.prevPath){
              query_params += "&contact[For_Website_Views__c]=true&contact[Listing_Website__c]="+process.env.GATSBY_SITE_URL+props.prevState.prevPath+"&contact[Property_ID__c]="+props.property_id+"&contact[Website_Views__c]=";
            }
            
          }

          //console.log("query_params", query_params);

          PropertyBaseAPI(query_params).then(response => {
            //console.log("property_base res", response)
            if(response.status === 200){
              console.log('property_base', 'success')
            } else{
              console.log('property_base', 'status error')
            }        
          }).catch(err =>
            console.log("property_base", "error")
          );
          /* ======== Property-base API ======== */

          if(formvalues.extra.buyer_owner === "Existing Property Owner"){

            var query_params2 = "contact[RecordTypeId]=0126g000000GHBsAAO&contact[FirstName]="+formvalues.first_name+"&contact[LastName]="+formvalues.last_name+"&contact[Email_2__c]="+formvalues.email+"&contact[MobilePhone]="+formvalues.telephone+"&contact[LeadSource]=website&"+props.form_post_params+"&contact[New_Website__c]=true&contact[Subscribed_For_Monthly_Newsletter__c]=true&contact[pba__LeadRoutingCompleted__c]=false"; 

            if(props.property_id){
              query_params2 += "&contact[pba__Comment_pb__c]="+props.property_id;
  
              if(props.sublisting_type){
                query_params2 += "&contact[Lead_Type__c]="+props.sublisting_type;
              }

              // Website views
              if(props.prevState.prevPath){
                query_params2 += "&contact[For_Website_Views__c]=true&contact[Listing_Website__c]="+process.env.GATSBY_SITE_URL+props.prevState.prevPath+"&contact[Property_ID__c]="+props.property_id+"&contact[Website_Views__c]=";
              }
            }

            PropertyBaseAPI(query_params2).then(response => {
              //console.log("property_base res", response)
              if(response.status === 200){
                console.log('property_base', 'success')
              } else{
                console.log('property_base', 'status error')
              }        
            }).catch(err =>
              console.log("property_base", "error")
            );

          }

          
 

        }

      });
      postStbFormData(raptorFormSchema(formvalues)).then(async apiRes => {  
        console.log('raptor submission', apiRes)
       });

      //console.log("form_values", formvalues.extra, props.form_type)
       

      // const axiosOptions_netlify = {
      //   url: fields[0].page_url,
      //   method: "post",
      //   headers: { "Content-Type": "application/x-www-form-urlencoded" },
      //   data: qs.stringify(formvalues),
      // }

      // axios(axiosOptions_netlify)
      //   .then(response => {
      //     console.log('success 2', 'data stored')
      //   })
      //   .catch(err =>
      //     console.log("err 2", err)
      //   );
      // const url = typeof window !== 'undefined' ? window.location.href : ''  
      // if (url.indexOf("property") > -1 && fields[4].event_tracking_decider) {
      //   fields[0].formname = 'Get mortgage help';
      // }

      // tracking event
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'formSubmit',
        'formType': 'form-'+fields[0].event_tracking,
        'formId': 'form-'+fields[0].event_tracking,
        'formName': fields[0].formname,
        'formLabel': props.formLabel
      });

        setShowerror(false);
        setThankyou(true);
        myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
        setTimeout(function(){
          navigate('/pre-qualified-confirmation/'+props.form_type);
        },2000)
        
        
      }
      processFromData();

    }
  }, [token]);

  const handleonVerify = token => {
    console.log("captcha verified");
    setToken(token);
  };

  const handleSubmit = event => {

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setShowerror(true);
      setValidated(true);
      setThankyou(false);
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
    else {

      event.preventDefault();

      const formsdata = (event.target);
      const json = {}
      Object.keys(formsdata).map(key => (
        json[formsdata[key].name] = (formsdata[key].checked) ? 'yes' : formsdata[key].value
      ))

      json['name'] = json.first_name+' '+json.last_name;
      json['email_temp_user'] = fields[0].email_temp_user;
      json['email_temp_admin'] = fields[0].email_temp_admin;
      json['formname'] = fields[0].formname;
      json['g_recaptcha_response'] = token;

      json['extra'] = props.form_values;
      json['email_subject_user'] = fields[0].email_subject_user;
      json['email_subject_admin'] = fields[0].email_subject_admin;

      //console.log("formsdata_json", json);

      setFormvalues(json);

      recaptchaRef.current.execute();

      setValidated(false);
        

      // reset form
      const form = event.target
      form.reset();

      props.clearFormState();
      
    }
  };

  // console.log ("qqqqq", props.url)

  return (
    <div className={`form ${props.container_classname}`}>
    <div ref={myRef} />
    {showerror && <div className="alert alert-danger ">
      {fields[0].error_text}
    </div>}

    {showthankyou && <div className="alert alert-success">
      {fields[0].success_text}
    </div>}

    
      <Form name={fields[0].formname} className={props.classNames} action="/thank-you/" method="post" noValidate validated={validated} onSubmit={handleSubmit} >

        <input type="hidden" name="form_name" value={fields[0].formname} />
        <input type="hidden" name="form_type" value={fields[0].form_type} />
        <input type="hidden" name="to_email_id" value={(props?.url && props?.url?.indexOf('dubai')>= 0) ? process.env.GATSBY_MAIL_TO_DUBAI:""} />
        
        <input type="hidden" name="bot-field" />

        <Form.Row>
        {fields.map((field, index) => {
            if ( "input" === field.element ) {
              return (
                <InputField
                  name={field.name}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  type={field.type}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  labelClass={field.labelClass}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  pattern={field.patternchk}
                  handlechange={handlechange}
                />
              );
            }
            if ("select" === field.element) {
              return (
                <SelectField
                  name={field.name}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  required={field.required}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  labelClass={field.labelClass}
                  values={field.values}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                  componentprops={props}
                />
              );
            }
            if ("textarea" === field.element) {
              return (
                <TextAreaField
                  name={field.name}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  rows={field.rows}
                  fieldClass={field.class}
                  labelClass={field.labelClass}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ("checkbox" === field.element) {
              return (
                <CheckboxField
                  name={field.name}
                  ref={field.ref}
                  value={field.value}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ("html" === field.element) {
              return (
                <HtmlBox
                  text={field.text}
                  fieldClass={field.class}
                  key={`${field.element}~${index}`}
                />
              );
            }
            if ("captcha" === field.element) {
              return (
                <ReCaptchaBox
                  fieldClass={field.class}
                  captRef={field.captchaRef}
                  key={`${field.element}~${index}`}
                  handleonVerify={handleonVerify}
                />
              );
            }

            if ("anchor" === field.element) {
                return(
                    <div className="btn-wrapper d-md-flex justify-content-between">
                        <a href="javascript:;" className="btn btn-step" onClick={props.prevClick}>
                            <span>Previous Step</span>
                        </a>
                    </div>
                )
            }
           

            if ("button" === field.element) {
              return (
                <>
                <ButtonField
                  name={field.name}
                  fieldClass={field.class}
                  type={field.type}
                  value={field.value}
                  key={`${field.element}~${index}`}
                  grpmd={field.grpmd}
                  customClass={field.customClass}
                />
                
                </>
              );
            }
          })
        }
        </Form.Row>
      </Form>
    </div>
  );
}


const PrequalificationFormPage = (props) => (
  <PrequalificationForm {...props} />
)

export default PrequalificationFormPage